'use strict';

import ngResource from 'angular-resource';

/* Services */


// Demonstrate how to register services
// In this case it is a simple value service.
export default angular.module('services', [ngResource]).
	service('authentication', ['$http', '$q', function($http, $q) {
		this._isLoggedIn = null;

		this.isLoggedIn = function() {
			if(this._isLoggedIn === null){
				this._isLoggedIn = $http.get('api.php/auth/isLoggedIn').then(function(result) {
					return result.data;
				});
			}
			return this._isLoggedIn;
		}

		this.login = function(username, password) {
			//First check logged in status.  If the get request has been done, it's basically a nop.
			//Otherwise we need to initialize _isLoggedIn.
			this.isLoggedIn();

			var me = this;
			return this._isLoggedIn.then(function(result) {
				if(result.isLoggedIn === true) {
					var deferred = $q.defer();
					var logoutPromise = me.logout();
					logoutPromise.then(function() {
						var res = me.login(username, password);
						res.then(function(result) {
							deferred.resolve(result);
						}, function(result) {
							deferred.reject(result);
						});
					});

					return deferred.promise;
				} else {
					me._isLoggedIn = $http.post('api.php/auth/login', {
						'username': username,
						'password': password
					}).then(function(result) {
						var nResult = angular.copy(result);
						nResult.isLoggedIn = result.loginSuccessful;
						return result.data;
					}, function(result) {
						return {'isLoggedIn': false};
					});
				}
				return me._isLoggedIn;
			});
		}

		var truePromise = $q.defer();
		truePromise.resolve();
		this.logout = function(username, password) {
			//First check logged in status.  If the get request has been done, it's basically a nop.
			//Otherwise we need to initialize _isLoggedIn.
			this.isLoggedIn();

			var me = this;
			return this._isLoggedIn.then(function(result) {
				if(result.isLoggedIn === true) {
					me._isLoggedIn = $http.post('api.php/auth/logout').then(function(result) {
						return {'isLoggedIn': false};
					});
					return me._isLoggedIn.then(function() {
						return true;
					});
				} else {
					return truePromise.deferred;
				}
			});
		}

		this.register = function(new_user) {
			//First check logged in status.  If the get request has been done, it's basically a nop.
			//Otherwise we need to initialize _isLoggedIn.
			this.isLoggedIn();

			var me = this;
			return this._isLoggedIn.then(function(result) {
				if(result.isLoggedIn === true) {
					var deferred = $q.defer();
					var logoutPromise = me.logout();
					logoutPromise.then(function() {
						var res = me.login(username, password);
						res.then(function(result) {
							deferred.resolve(result);
						}, function(result) {
							deferred.reject(result);
						});
					});

					return deferred.promise;
				} else {
					me._isLoggedIn = $http.post('api.php/auth/register', new_user).then(function(result) {
						var nResult = angular.copy(result);
						nResult.isLoggedIn = result.loginSuccessful;
						return result.data;
					}, function(result) {
						return {'isLoggedIn': false};
					});
				}
				return me._isLoggedIn;
			});
		}
	}])
	.service('infoPanelWatch', function() {
		var watchers = {};
		this.watch = function(tag, show_func, hide_func) {
			var tag_watchers;
			if (watchers.hasOwnProperty(tag)) {
				tag_watchers = watchers[tag];
			} else {
				tag_watchers = {
					show_callbacks: {},
					hide_callbacks: {},
					next_id: 0
				};
				watchers[tag] = tag_watchers;
			}
			var my_id = tag_watchers.next_id++;
			tag_watchers.show_callbacks[my_id] = show_func;
			tag_watchers.hide_callbacks[my_id] = hide_func;
			return my_id;
		}
		this.stopWatch = function(tag, watchId) {
			delete watchers[tag].show_callbacks[watchId];
			delete watchers[tag].hide_callbacks[watchId];
		}
		var do_callbacks = function(tag, callback_name, args) {
			if(watchers.hasOwnProperty(tag)) {
				var callbacks = watchers[tag][callback_name];

				for (var id in callbacks) {
					if (callbacks.hasOwnProperty(id)) {
						callbacks[id].apply(null, args);
					}
				}
			}
		}
		this.show = function(tag, msg) {
			do_callbacks(tag, 'show_callbacks', [tag, msg]);
		}
		this.hide = function(tag) {
			do_callbacks(tag, 'hide_callbacks', [tag]);
		}
	})
	.factory('building', ['$resource', function($resource){
		return $resource('api.php/building')
	}])
	.factory('building_pretty_list', ['$resource', function($resource){
		return $resource('api.php/pretty_building_list')
	}])
	.factory('portfolio', ['$resource', function($resource){
		return $resource('api.php/portfolio/:portfolioId')
	}])
	.factory('portfolio_building', ['$resource', function($resource){
		return $resource('api.php/portfolio/:portfolioId/building/:buildingId')
	}])
	.factory('building_output', ['$resource', function($resource){
		return $resource('api.php/building_output', {}, { 'fetch_for': {method: 'POST'} })
	}])
	.factory('building_single_parameter_recommendations', ['$resource', function($resource){
		return $resource('api.php/building_single_parameter_recommendations', {}, { 'fetch_for': {method: 'POST'} })
	}])
	.factory('building_multiple_parameter_recommendations', ['$resource', function($resource){
		return $resource('api.php/building_multiple_parameter_recommendations', {}, { 'fetch_for': {method: 'POST'} })
	}])
	.factory('building_solar_generation', ['$resource', function($resource){
		return $resource('api.php/building_solar_generation', {}, { 'fetch_for': {method: 'POST'} })
	}])
	.factory('building_wind_generation', ['$resource', function($resource){
		return $resource('api.php/building_wind_generation', {}, { 'fetch_for': {method: 'POST'} })
	}])
	.factory('building_geothermal_generation', ['$resource', function($resource){
		return $resource('api.php/building_geothermal_generation', {}, { 'fetch_for': {method: 'POST'} })
	}])
.name;
