'use strict';

import '../css/app.less'

import angular from 'angular';
import ngRoute from 'angular-route';
import ngSanitize from 'angular-sanitize';

import filters from './filters';
import services from './services';
import directives from './directives';
import components from './components';

import about from './about'
import building from './building'
import portfolio from './portfolio';

import * as ctrl from './controllers';

import './lib/bootstrap';

// Declare app level module which depends on filters, and services
export default angular.module('myApp', [ngRoute, ngSanitize, filters, services, directives, components, building, portfolio, about]).
config(function($routeProvider) {
	'ngInject';
	$routeProvider.when('/register', {templateUrl: 'partials/register.html', controller: ctrl.RegisterCtrl});
	$routeProvider.when('/login', {templateUrl: 'partials/login.html', controller: ctrl.LoginCtrl});
	$routeProvider.when('/logout', {templateUrl: 'partials/logout.html', controller: ctrl.LogoutCtrl});
	$routeProvider.when('/about', {template: '<about-page></about-page>'})
	$routeProvider.when('/building', {templateUrl: 'partials/building.html', controller: ctrl.BuildingCtrl});
	$routeProvider.when('/building/:buildingId', {templateUrl: 'partials/building.html', controller: ctrl.BuildingCtrl});
	$routeProvider.when('/portfolio/:portfolioId', {templateUrl: 'partials/portfolio.html', controller: ctrl.PortfolioCtrl});
  $routeProvider.otherwise({redirectTo: '/login'});
})
.config(function($locationProvider) {
    'ngInject';
    $locationProvider.html5Mode(true);
})
.controller('TopLevelCtrl', ctrl.TopLevelCtrl)
.name;
