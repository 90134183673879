'use strict';

import $ from 'jquery';

export default function PortfolioCtrl($scope, $routeParams, $location, building, portfolio, portfolio_building, building_output, building_single_parameter_recommendations, building_multiple_parameter_recommendations, building_solar_generation, building_wind_generation, building_geothermal_generation) {
	'ngInject';

	$scope.portfolio_buildings = {};
	$scope.buildings_output = {};

	$scope.update_building = function(building_id) {
		if ($scope.portfolio_buildings[building_id]) { // Make sure we are in the portfolio!
			portfolio_building.save({'portfolioId': $routeParams.portfolioId, 'buildingId': building_id}, {});
		} else { // And remove the building.
			portfolio_building.remove({'portfolioId': $routeParams.portfolioId, 'buildingId': building_id});
		}
	}
	$scope.updateSelection = (change) => {
		$scope.update_building(change.buildingId)
	}

	if('portfolioId' in $routeParams){
		$scope.portfolio = portfolio.get({'portfolioId': $routeParams.portfolioId});
		portfolio_building.query({'portfolioId': $routeParams.portfolioId}, function(data) {
			$scope.portfolio_buildings = {};
			$.each(data, function(index, value) {
				$scope.portfolio_buildings[value.building_id] = true;
			});
		});
	} else {
		$location.path('/building');
	}

	$scope.$watch("[portfolio_buildings, buildings]", function(data, oldData) {
		if (data === oldData) { return; }
		var old_data = $scope.buildings_output
		$scope.buildings_output = {}
		$.each(data[1], function(index, value) {
			if (data[0][value.id] === true) {
				if (old_data[value.id] === undefined) {
					$scope.buildings_output[value.id] = {
						'name': value.address1,
						'building': building.get({'buildingId': value.id}),
						'data': building_output.get({'buildingId': value.id})
					};
				} else {
					$scope.buildings_output[value.id] = old_data[value.id];
				}
			}
		});
	}, true);

	$scope.summer = function(data) {
		if (data.building_calculations === undefined) {
			return ;
		}
		var ret = 0;
		$.each(data.building_calculations, function(index, value) {
			$.each(value, function(index, value) {
				ret += value;
			});
		});
		return ret;
	}

	$scope.subMenuSelection = (item) => {
		$scope.visibleComponent = item.name
	}

	$scope.menu_items = [
		{
			name: 'selection',
			text: 'Building selection',
		},
		{
			name: 'summary',
			text: 'Total consumption',
			items: []
		},
	];
	$scope.subMenuSelection($scope.menu_items[0])
}
