'use strict';

import angular from 'angular'

import html from './province-option.html'

let CAProvinces = {
	'ab': 'Alberta',
	'bc': 'British Columbia',
	'mb': 'Manitoba',
	'nb': 'New Brunswick',
	'nl': 'Newfoundland and Labrador',
	'ns': 'Nova Scotia',
	'on': 'Ontario',
	'nt': 'Northwest Territories',
	'nu': 'Nunavut',
	'pe': 'Prince Edward Island',
	'qc': 'Quebec',
	'sk': 'Saskatchewan',
	'yt': 'Yukon',
}

let USStates = {
	'al': 'Alabama',
	'ak': 'Alaska',
	'as': 'American Samoa',
	'az': 'Arizona',
	'ar': 'Arkansas',
	'ca': 'California',
	'co': 'Colorado',
	'ct': 'Connecticut',
	'de': 'Delaware',
	'dc': 'District of Columbia',
	'fl': 'Florida',
	'ga': 'Georgia',
	'gu': 'Guam',
	'hi': 'Hawaii',
	'id': 'Idaho',
	'il': 'Illinois',
	'in': 'Indiana',
	'ia': 'Iowa',
	'ks': 'Kansas',
	'ky': 'Kentucky',
	'la': 'Louisiana',
	'me': 'Maine',
	'md': 'Maryland',
	'ma': 'Massachusetts',
	'mi': 'Michigan',
	'mn': 'Minnesota',
	'ms': 'Mississippi',
	'mo': 'Missouri',
	'mt': 'Montana',
	'ne': 'Nebraska',
	'nv': 'Nevada',
	'nh': 'New Hampshire',
	'nj': 'New Jersey',
	'nm': 'New Mexico',
	'ny': 'New York',
	'mp': 'Northern Mariana Islands',
	'nc': 'North Carolina',
	'nd': 'North Dakota',
	'oh': 'Ohio',
	'ok': 'Oklahoma',
	'or': 'Oregon',
	'pa': 'Pennsylvania',
	'pr': 'Puerto Rico',
	'ri': 'Rhode Island',
	'sc': 'South Carolina',
	'sd': 'South Dakota',
	'tn': 'Tennessee',
	'tx': 'Texas',
	'um': 'United States Minor Outlying Islands',
	'ut': 'Utah',
	'vt': 'Vermont',
	'va': 'Virginia',
	'vi': 'Virgin Islands, U.S.',
	'wa': 'Washington',
	'wv': 'West Virginia',
	'wi': 'Wisconsin',
	'wy': 'Wyoming',
}

let provinceMap = {
	'ca': CAProvinces,
	'us': USStates,
}

let provinceStringMap = {
	'ca': 'Province',
	'us': 'State',
}

export default angular.module('components.province-option', []).component('provinceOption', {
	templateUrl: html,
	bindings: {
		country: '<',
		province: '<',
		onUpdate: '&',
	},
	controller: function(){
		let ctrl = this

		function updateProvinces() {
			ctrl.provinces = provinceMap[ctrl.country]
			ctrl.province_string = provinceStringMap[ctrl.country]
		}

		ctrl.$onInit = function() {
			updateProvinces()
			ctrl.provinceCopy = ctrl.province
		}
		ctrl.$onChanges = function() {
			updateProvinces()
		}

		ctrl.selectionChanged = function() {
			ctrl.onUpdate({value: ctrl.provinceCopy})
		}
	},
})
.name
