'use strict';

import angular from 'angular';

function sumMonths(data) {
	return data.january +
		data.february +
		data.march +
		data.april +
		data.may +
		data.june +
		data.july +
		data.august +
		data.september +
		data.october +
		data.november +
		data.december;
}

export default angular.module('portfolio.summary', []).component('portfolioSummary', {
	templateUrl: 'js/portfolio/summary.html',
	bindings: {
		buildings: '<',
	},
	controller: function($scope){
		'ngInject';

		let ctrl = this;

		ctrl.buildings_processed = [];
		ctrl.sortOption = 'name';
		ctrl.reverseSort = true;
		ctrl.arrowStyle = 'up';
		ctrl.showDetails = [false, false, false];
		ctrl.lowest_consuming = null;
		ctrl.highest_consuming = null;

		ctrl.comparisonOptions = [
			{
				id: 0,
				label: 'None',
			},
			{
				id: 2,
				label: 'Lowest Consuming Building',
			},
			{
				id: 3,
				label: 'Highest Consuming Building',
			},
			{
				id: 1,
				label: 'Consumption Type'
			}
		];

		ctrl.comparisonSelect = ctrl.comparisonOptions[0];

		ctrl.showPercentage = function(building) {
			if (ctrl.comparisonSelect.id == 1) {
				return building.relative_sums;
			} else {
				return building.sums;
			}
		}

		ctrl.showRelative = function(building, total_selection) {
			if (ctrl.comparisonSelect.id == 2) {
				return building.relative_totals.lowest[total_selection];
			} else if (ctrl.comparisonSelect.id == 3) {
				return building.relative_totals.highest[total_selection];
			} else {
				return building.sums_total[total_selection];
			}
		}

		ctrl.setSortOption = function(option) {
			ctrl.reverseSort = ctrl.sortOption == option ? !ctrl.reverseSort : false;
			ctrl.sortOption = option;
			ctrl.arrowStyle = ctrl.reverseSort ? 'down' : 'up';
		};

		$scope.$watchCollection('$ctrl.buildings', function(data) {
			// Reset the summaries here, so in case some promise resolve from this round after an updated set resolve,
			// it will be set into this object, instead of the new object.
			ctrl.buildings_processed = [];
			Object.keys(data).forEach(function(id) {
				let building = data[id];
				// Capture a specific object that is put in the above object.  Using 'ctrl.buildings_summaries' with the
				// promise captures ctrl, not the building_summaries.  This ensure the right thing is captured below.
				let summaries = [];
				let total_sums = {};

				let processed_building = {
					id: id,
					name: building.name,
					building: {},
					sums: summaries,
					sums_total: total_sums,
					relative_sums: [],
					relative_totals: {
						lowest: {},
						highest: {}
					},
					heating_sum_bounds: {},
					electrial_sum_bounds: {},
				};
				ctrl.buildings_processed.push(processed_building);
				
				building.data.$promise.then(function(output) {
					let building_calcuations = output.building_calculations;
					summaries.push({name: 'Boiler', sum: sumMonths(building_calcuations.boiler)});
					summaries.push({name: 'Chilled Water Pumps', sum: sumMonths(building_calcuations.chilled_water_pumps)});
					summaries.push({name: 'Chiller', sum: sumMonths(building_calcuations.chiller)});
					summaries.push({name: 'Condenser Water Pumps', sum: sumMonths(building_calcuations.condenser_water_pumps)});
					summaries.push({name: 'DHW', sum: sumMonths(building_calcuations.dhw)});
					summaries.push({name: 'Equipment', sum: sumMonths(building_calcuations.equipment)});
					summaries.push({name: 'Fans', sum: sumMonths(building_calcuations.fans)});
					summaries.push({name: 'Lighting', sum: sumMonths(building_calcuations.lighting)});
					summaries.push({name: 'Hot Water Pumps', sum: sumMonths(building_calcuations.hot_water_pumps)});

					// state the array bounds of the the various types of consumptions (heating, electrical, etc.) in the summaries object to traverse in the view
					processed_building.heating_sum_bounds = {start: 0, end: 1}
					processed_building.electrical_sum_bounds = {start: 1, end: summaries.length};

					// calculates the total of each consumption type by adding all the respective values from the summaries object
					total_sums.heating = (summaries.slice(processed_building.heating_sum_bounds.start, processed_building.heating_sum_bounds.end)
						.map(sum => sum.sum)).reduce((total, sum) => total + sum);
					total_sums.electric = (summaries.slice(processed_building.electrical_sum_bounds.start, processed_building.electrical_sum_bounds.end)
						.map(sum => sum.sum)).reduce((total, sum) => total + sum);
					total_sums.total = total_sums.heating + total_sums.electric;

					if (ctrl.lowest_consuming) {
						ctrl.lowest_consuming = total_sums.total < ctrl.lowest_consuming.sums_total.total ? processed_building : ctrl.lowest_consuming; 
					} else {
						ctrl.lowest_consuming = processed_building;
					}

					if (ctrl.highest_consuming) {
						ctrl.highest_consuming = total_sums.total > ctrl.highest_consuming.sums_total.total ? processed_building : ctrl.highest_consuming; 
					} else {
						ctrl.highest_consuming = processed_building;
					}
					calculateEnergyIntensity(processed_building);
				});
				building.building.$promise.then(function(output) {
					processed_building.building = output;
					calculateEnergyIntensity(processed_building);
				});
			})
		});

		function calculateEnergyIntensity(processed_building) {
			let buildingData = processed_building.building;
			let sums = processed_building.sums;
			if (!sums || !buildingData || !ctrl.lowest_consuming || !ctrl.highest_consuming) {
				return;
			}
			processed_building.sums_total.energy_intensity = (processed_building.sums_total.total) * 3.6 / ((+buildingData.num_storeys + +buildingData.number_of_basements) * +buildingData.floor_area)
			calculateRelativeValues(ctrl.lowest_consuming, ctrl.highest_consuming);
		}

		function calculateRelativeValues(lowest_consuming, highest_consuming) {
			for (let building of ctrl.buildings_processed) {
				let lowest = building.relative_totals.lowest;
				let highest = building.relative_totals.highest;

				let totals = building.sums_total;
				let lowest_totals = lowest_consuming.sums_total;
				let highest_totals = highest_consuming.sums_total

				let sums = building.sums;
				let relative_sums = building.relative_sums;

				//change heating, electrical, total, and energy intensity to relative values based on lowest and highest consuming values
				lowest.heating = ((totals.heating - lowest_totals.heating) / lowest_totals.heating) * 100;
				lowest.electric = ((totals.electric - lowest_totals.electric) / lowest_totals.electric) * 100;
				lowest.total = ((totals.total - lowest_totals.total) / lowest_totals.total) * 100;
				lowest.energy_intensity = ((totals.energy_intensity - lowest_totals.energy_intensity) / lowest_totals.energy_intensity) * 100;

				highest.heating = ((totals.heating - highest_totals.heating) / totals.heating) * 100;
				highest.electric = ((totals.electric - highest_totals.electric) / totals.electric) * 100;
				highest.total = ((totals.total - highest_totals.total) / totals.total) * 100;
				highest.energy_intensity = ((totals.energy_intensity - highest_totals.energy_intensity) / totals.energy_intensity) * 100;

				// delcare the start and end bounds for each consumption type in an array to enable iteration in the next step
				let bounds = [
					{bound: building.heating_sum_bounds, sum: totals.heating},
					{bound: building.electrical_sum_bounds, sum: totals.electric},
				];

				for (let bound of bounds) {
					for (let summary of sums.slice(bound.bound.start, bound.bound.end)) {
						relative_sums.push({
							name: summary.name,
							sum: summary.sum / bound.sum * 100
						});
					}
				}
			}
		}
	},
})
.name;
