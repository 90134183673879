'use strict';

import angular from 'angular';

import html from './about-page.html';

export default angular.module('about.about-page', []).component('aboutPage', {
   templateUrl: html,
})
.name
