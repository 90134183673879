import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'

import { NgxDatatableModule } from '@swimlane/ngx-datatable'

import { PortfolioSelectionComponent } from './portfolio-selection.component'

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		NgxDatatableModule,
	],
	declarations: [
		PortfolioSelectionComponent,
	],
	entryComponents: [
		PortfolioSelectionComponent,
	],
})
export class PortfolioModule {
}
