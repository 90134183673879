'use strict';

import BuildingCtrl from './controllers.d/building';
import PortfolioCtrl from './controllers.d/portfolio';
import TopLevelCtrl from './controllers.d/toplevel';

/* Controllers */


function LoginCtrl($scope, authentication) {
	'ngInject';

	$scope.username = "";
	$scope.password = "";
	$scope.isloggedin = authentication.isLoggedIn().then(function(result){
		if(result.isLoggedIn) {
			$scope.$emit('UserLoggedOn', result.user);
		}
	});

	$scope.preformLogin = function() {
		$scope.isloggedin = authentication.login($scope.username, $scope.password).then(function(result){
			if(result.isLoggedIn == true){
				$scope.$emit('UserLoggedOn', result.user);
			}
			return result;
		});
	}
}

function RegisterCtrl($scope, authentication) {
	'ngInject';

	$scope.user = {};
	$scope.isloggedin = authentication.isLoggedIn().then(function(result){
		if(result.isLoggedIn) {
			$scope.$emit('UserLoggedOn', result.user);
		}
	});

	$scope.preformRegister = function() {
		if ($scope.user.email != $scope.user.email_confirm) {
			alert("M EMAIL");
			return ;
		}
		if ($scope.user.password != $scope.user.password_confirm) {
			alert("M PASSWORD");
			return ;
		}
		$scope.isloggedin = authentication.register($scope.user).then(function(result){
			if(result.isLoggedIn == true){
				$scope.$emit('UserLoggedOn', result.user);
			}
			return result;
		});
	}
}

function LogoutCtrl($scope, $location, authentication) {
	'ngInject';

	authentication.logout().then(function(){
		$scope.$emit('UserLoggedOff');
		$location.path('/login');
	});
}

export { RegisterCtrl, LoginCtrl, LogoutCtrl, BuildingCtrl, PortfolioCtrl, TopLevelCtrl };
