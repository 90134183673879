'use strict';

import ngSanitize from 'angular-sanitize';

import jqplot from 'jqplot-exported/jqplot';

import services from './services';

/* Directives */


export default angular.module('directives', [services, ngSanitize]).directive('jqplot', function () {
		return {
			restrict:'A',
			replace:true,
			template:'<div></div>',
			link:function (scope, element, attrs) {
				var redraw = function (newScopeData) {
					element.empty();
					var chart = jqplot(attrs.id,  newScopeData.data, newScopeData);
				};

				scope.$watch(attrs.options, redraw, true);
			}
		}
	}
)
.directive('buildingFieldHelp', ['infoPanelWatch', function (infoPanelWatch) {
		return {
			restrict:'A',
			link:function (scope, element, attrs) {
				var attr_val = attrs.buildingFieldHelp, shown = false;
				attrs.$observe('buildingFieldHelp', function(val) {
					attr_val = val;
					if (shown == true) {
						infoPanelWatch.show('BuildingFieldInfo', val);
					}
				});
				element.mouseenter(function() {
					scope.$apply(function() {
						infoPanelWatch.show('BuildingFieldInfo', attr_val);
						shown = true;
					});
				});
				element.mouseleave(function() {
					scope.$apply(function() {
						shown = false;
						infoPanelWatch.hide('BuildingFieldInfo');
					});
				});
			}
		}
	}]
).directive('buildingInfoPanel', ['infoPanelWatch', function (infoPanelWatch) {
		return {
			restrict:'A',
			replace:true,
			scope: {},
			template:'<div class="building-field-help" ng-bind-html="contents"></div>',
			link:function (scope, element, attrs) {
				scope.contents = "";
				infoPanelWatch.watch('BuildingFieldInfo', function(tag, msg) {
					scope.contents = msg;
				}, function(tag) {
					scope.contents = "";
				});
			}
		}
	}]
).directive('modelCalibration', function() {
	return {
		restrict:'A',
		replace:true,
		scope: {
			calibration: '=modelCalibration',
			labelHelp: '@'
		},
		template:'		<div><label building-field-help="{{labelHelp}}">Model calibration</label>\
		<input class="model-cal-input" type="text" readonly="true" ng-model="calibratedValueWithPercent">\
		<span ng-class="modelStatus">&nbsp;</span></div>',
		link:function (scope, element, attrs) {
			scope.$watch('calibration', function(val) {
				scope.calibratedValueWithPercent = (val?val:0) + "%";
				if(Math.abs(scope.calibration) < 15) {
					scope.modelStatus = "model-pass";
				} else {
					scope.modelStatus = "model-fail";
				}
			});
		}
	}
}).directive('solarConfiguration', function() {
	return {
		restrict:'A',
		replace:true,
		template:'<select ng-options="c.name as c.label for c in opts"></select>',
		scope: true,
		link:function (scope, element, attrs) {
			scope.opts = [
				{name: 'FIXED_SLOPE_FACING_DUE_SOUTH',
				label: 'Fixed slope facing due south'},
				{name: 'FIXED_SLOPE_NOT_FACING_DUE_SOUTH',
				label: 'Fixed slope not facing due south'},
				{name: 'FIXED_SLOPE_ON_A_VERTICAL_AXIS',
				label: 'Fixed slope on a vertical axis'},
				{name: 'TRACKING_ON_TWO_AXES',
				label: 'Tracking on two axes'}
			];
		}
	}
}).directive('arrayType', function() {
	return {
		restrict:'A',
		replace:true,
		template:'<select ng-options="c.name as c.label for c in opts"></select>',
		scope: true,
		link:function (scope, element, attrs) {
			scope.opts = [
				{name: 'MONO_SI',
				label: 'Mono-Si'},
				{name: 'POLY_SI',
				label: 'Poly-Si'},
				{name: 'A_SI',
				label: 'a-Si'},
				{name: 'CDTE',
				label: 'CDTE'},
				{name: 'CIS',
				label: 'CIS'}
			];
		}
	}
}).directive('windHawtModel', function() {
	return {
		restrict:'A',
		replace:true,
		template:'<select ng-options="m.name as m.label for m in opts"></select>',
		scope: true,
		link:function (scope, element, attrs) {
			scope.opts = [
				{name: 1,
				label: 'Model 1'},
				{name: 2,
				label: 'Model 2'},
				{name: 3,
				label: 'Model 3'},
				{name: 4,
				label: 'Model 4'},
				{name: 5,
				label: 'Model 5'},
				{name: 6,
				label: 'Model 6'},
				{name: 7,
				label: 'Model 7'},
			];
		}
	}
}).directive('geothermalSoilType', function() {
	return {
		restrict:'A',
		replace:true,
		template:'<select ng-options="s.name as s.label for s in opts"></select>',
		scope: true,
		link:function (scope, element, attrs) {
			scope.opts = [
				{name: 'LIGHT_SOIL_DAMP',
				label: 'Light soil - damp'},
				{name: 'LIGHT_SOIL_DRY',
				label: 'Light soil - dry'},
				{name: 'HEAVY_SOIL_DAMP',
				label: 'Heavy soil - damp'},
				{name: 'HEAVY_SOIL_DRY',
				label: 'Heavy soil - dry'},
				{name: 'LIGHT_ROCK',
				label: 'Light rock'},
				{name: 'HEAVY_ROCK',
				label: 'Heavy rock'},
				{name: 'PERMAFROST_LIGHT',
				label: 'Permafrost - light'},
				{name: 'PERMAFROST_DENSE',
				label: 'Permafrost - dense'},
			];
		}
	}
})
.name;
