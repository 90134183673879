import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { downgradeComponent } from '@angular/upgrade/static'

import * as angular from 'angular'

import { PortfolioBuilding } from './portfolio-building.model'
import { PortfolioSelectedBuildings } from './portfolio-selected-buildings.model'
import { PortfolioSelectionChange } from './portfolio-selection-change.model'

@Component({
	selector: 'portfolio-selection',
	template: require('./portfolio-selection.component.ahtml'),
	styles: [ require('./portfolio-selection.component.less') ],
})
export class PortfolioSelectionComponent implements OnChanges {
	@Input() public buildings: Promise<PortfolioBuilding[]>
	@Input() public selectedBuildings: PortfolioSelectedBuildings = {}

	@Output() public change = new EventEmitter<PortfolioSelectionChange>()

	public buildingsResolved: PortfolioBuilding[] = []
	public buildingsResolvedCopy: PortfolioBuilding[] = []
	public selected: PortfolioBuilding[] = []

	public searchQuery: string

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.buildings && this.buildings) {
			this.buildings.then((data: PortfolioBuilding[]) => {
				this.buildingsResolvedCopy = data
				data.forEach((building: PortfolioBuilding) => {
					if (this.selectedBuildings[building.id]) {
						this.selected.push(building)
					}
				})
				this.updateFilter()
			})
		}
		if (changes.selectedBuildings) {
			this.buildingsResolvedCopy.forEach((building: PortfolioBuilding) => {
				if (this.selectedBuildings[building.id]) {
					this.selected.push(building)
				}
			})
		}
	}

	public buildingChange(buildingId: number) {
		this.change.emit({
			buildingId,
			present: this.selectedBuildings[buildingId],
		})
	}

	public updateFilter() {
		this.buildingsResolved = this.buildingsResolvedCopy.filter((item) => {
			return !this.searchQuery ||
				(item.address1 && item.address1.includes(this.searchQuery)) ||
				(item.city && item.city.includes(this.searchQuery)) ||
				(item.postal_code && item.postal_code.includes(this.searchQuery)) ||
				(item.year_built && item.year_built.toString().includes(this.searchQuery))
		})
	}
}

export default angular.module('portfolio.selection', [])
.directive(
	'portfolioSelection',
	downgradeComponent({component: PortfolioSelectionComponent}) as angular.IDirectiveFactory,
)
.name
