import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { downgradeComponent } from '@angular/upgrade/static'

import * as angular from 'angular'

import { MenuItem } from './menu-item.model'

@Component({
	selector: 'sub-menu',
	template: require('./sub-menu.template.ahtml'),
})
export class SubMenuComponent implements OnChanges {
	@Input()  public items: MenuItem[]
	@Output() public change = new EventEmitter<MenuItem>()

	private current: MenuItem

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.items && this.items.length > 0) {
			this.changeActive(this.items[0])
		}
	}

	private changeActive(item: MenuItem): void {
		if (this.current !== item) {
			this.current = item
			this.change.emit(item)
		}
	}
}

export default angular.module('components.sub-menu', [])
.directive('subMenu', downgradeComponent({component: SubMenuComponent}) as angular.IDirectiveFactory)
.name
