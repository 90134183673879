'use strict';

import angular from 'angular';

export default angular.module('components.country-option', []).component('countryOption', {
	templateUrl: 'js/components/country-option.html',
	bindings: {
		country: '<',
		idLink: '@',
		onUpdate: '&',
	},
	controller: function($scope, $element){
		'ngInject';

		let ctrl = this
		let countryCopy = undefined

		ctrl.countryGS = function(newVal) {
			if (newVal !== undefined && countryCopy != newVal) {
				countryCopy = newVal
				ctrl.onUpdate({value: newVal})
			}
			return countryCopy
		}

		ctrl.$onInit = function() {
			if (ctrl.country === undefined) {
				ctrl.countryGS('us')
			} else {
				countryCopy = ctrl.country
			}
			if (ctrl.idLink !== undefined) {
				$element[0].firstChild.id = ctrl.idLink
			}
		}
	},
})
.name;
