import './polyfills'

import * as $ from 'jquery'

import 'angular'

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app.module'
$(document).ready(() => {
	platformBrowserDynamic().bootstrapModule(AppModule)
})
