import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { UpgradeModule } from '@angular/upgrade/static'

import app from './app'

import { PortfolioModule } from './portfolio/portfolio.module'

import { SubMenuComponent } from './components/sub-menu.component'

@NgModule({
	imports: [
		BrowserModule,
		UpgradeModule,
		PortfolioModule,
	],
	declarations: [
		SubMenuComponent,
	],
	entryComponents: [
		SubMenuComponent,
	],
})
export class AppModule {
	constructor(private upgrade: UpgradeModule) { }
	private ngDoBootstrap() {
		this.upgrade.bootstrap(document.body, [app], { strictDi: true })
	}
}
