'use strict';

/* Filters */

export default angular.module('filters', []).
filter('stringLimit', function() {
	return function(input, len) {
		if (typeof input == "string") {
			return input.substring(0, len);
		} else {
			return input;
		}
	};
}).
filter('percentDifference', function() {
	return function(input, original) {
		return 100 - input / original * 100
	};
}).
filter('htmlRegexStrip', function() {
	return function(input) {
		return input ? input.replace(/<[^>]+>/gm, '') : input
	}
}).
name;
