'use strict';

import 'jqplot-exported/ShadowRenderer';
import 'jqplot-exported/ShapeRenderer';
import 'jqplot-exported/LineRenderer';
import 'jqplot-exported/LinearAxisRenderer';
import 'jqplot-exported/TableLegendRenderer';
import 'jqplot-exported/AxisTickRenderer';
import { MeterGaugeRenderer } from 'jqplot-exported/plugins/MeterGaugeRenderer';

export default function TopLevelCtrl($scope, $location, $routeParams, authentication, building, building_pretty_list, portfolio) {
	'ngInject';
	$scope.isLoggedIn = false;


	var fetch_pretty_building_list = function() {
		$scope.buildings = building_pretty_list.query();
	};
	var fetch_pretty_portfolio_list = function() {
		$scope.portfolios = portfolio.query();
	};
	var fetch_pretty_lists = function() {
		fetch_pretty_building_list();
		fetch_pretty_portfolio_list();
	};

	authentication.isLoggedIn().then(function(res) {
		if(!res.isLoggedIn && $location.path() != "/login" && $location.path() != "/register") {
			$location.path("/login");
		} else if(res.isLoggedIn) {
			fetch_pretty_lists();
			$scope.isLoggedIn = true;
			$scope.user = res.user;
		}
	});

	$scope.$on('UserLoggedOn', function(event, user) {
		$location.path("/building");
		fetch_pretty_lists();
		$scope.isLoggedIn = true;
		$scope.user = user;
	});

	$scope.$on('UserLoggedOff', function(event, args) {
		//Clear the lists.
		$scope.buildings = [];
		$scope.portfolios = [];

		$scope.isLoggedIn = false;
		delete $scope.user;
	});

	$scope.$on('ChangePageTitle', function(event, title) {
		$scope.page_title = title;
	});

	$scope.$on('$routeChangeSuccess', function() {
		$scope.page_title = '';
	});

	$scope.$on('RefetchBuildingList', fetch_pretty_building_list);

	$scope.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	$scope.date = new Date();

	$scope.meter_chart_options = {
		data: [[200]],
		title: 'BEUI (MJ/m<sup>2</sup>)',
		seriesDefaults: {
			renderer: MeterGaugeRenderer,
			rendererOptions: {
				//label: 'Normalized Energy Intensity',
				labelPosition: 'bottom',
				showTickLabels: true,
				min: 600,
				max: 1500,
				intervalColors:['#30FF30', '#FF3030'],
				needleColor: '#1B31BF'
			}
		}
	}
	$scope.$on('ChangeMeterValue', function(event, new_value, new_benchmark_value) {
		$scope.meter_chart_options.data = [[new_value]];
		$scope.meter_chart_options.seriesDefaults.rendererOptions.intervals = [new_benchmark_value, $scope.meter_chart_options.seriesDefaults.rendererOptions.max];
	});

	$scope.new_building = function() {
	}
	$scope.delete_building = function(msg) {
		building.remove(msg);
		$scope.buildings.splice($.inArray(msg, $scope.buildings), 1);
	};

	$scope.new_portfolio = function() {
		var portfolio_name = prompt("Portfolio name?");
		portfolio.save({}, {'name': portfolio_name}, function(ret) {
			if('new_id' in ret) {
				$location.path('/portfolio/'+ret.new_id);
				fetch_pretty_portfolio_list();
			} else {
				alert("Something bad happened!");
			}
		});
	}
}
