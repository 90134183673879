'use strict';

import angular from 'angular';

import summary from './summary';

import portfolio_selection from './portfolio-selection.component'

export default angular.module('portfolio', [summary, portfolio_selection])
.name;
