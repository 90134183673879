'use strict';

import angular from 'angular'
import ngSanitize from 'angular-sanitize';

import filters from '../filters'

import html from './conversion-input.html'

export default angular.module('building.conversion-input', [filters, ngSanitize]).component('conversionInput', {
	templateUrl: html,
	bindings: {
		label: '@',
		help: '@',
		value: '<',
		conversion: '<',
		digits: '<',
		secondDigits: '<',
		firstUnit: '@',
		secondUnit: '@',
		onUpdate: '&',
	},
	controller: function(){
		this.$onInit = () => {
			if (this.secondDigits == undefined) {
				this.secondDigits = this.digits
			}
			this.valueCopy = this.value
			this.valueConverted = +Number(this.valueCopy * this.conversion).toFixed(this.secondDigits)
		}
		this.$onChanges = () => {
			if (this.secondDigits == undefined) {
				this.secondDigits = this.digits
			}
			if (this.valueCopy !== this.value) {
				this.valueCopy = this.value
				this.valueConverted = +Number(this.valueCopy * this.conversion).toFixed(this.secondDigits)
			}
		}

		this.updateValue = () => {
			this.valueConverted = +Number(this.valueCopy * this.conversion).toFixed(this.secondDigits)
			this.onUpdate({value: this.valueCopy})
		}

		this.updateConversion = () => {
			this.valueCopy = +Number(this.valueConverted / this.conversion).toFixed(this.digits)
			this.onUpdate({value: this.valueCopy})
		}
	},
})
.name
