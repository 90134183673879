'use strict';

import angular from 'angular'
import ngSanitize from 'angular-sanitize';

import filters from '../filters'

export default angular.module('building.city-lookup', []).service('CityLookup', function($http, $q) {
	'ngInject'

	this.fetchCity = (country, postal_code) => {
		return $http.post('api.php/misc/lookupcity', {
			country: country,
			postal_code: postal_code,
		}).then((response) => {
			return response.data
		}, () => {
			return $q.reject()
		})
	}
})
.name
