'use strict';

import $ from 'jquery';

import 'jqplot-exported/plugins/CanvasTextRenderer';
import 'jqplot-exported/AxisTickRenderer';
import 'jqplot-exported/TableLegendRenderer';
import 'jqplot-exported/LinearAxisRenderer';
import 'jqplot-exported/ShadowRenderer';
import 'jqplot-exported/ShapeRenderer';
import 'jqplot-exported/LineRenderer';
import { BarRenderer } from 'jqplot-exported/plugins/BarRenderer';
import { CategoryAxisRenderer } from 'jqplot-exported/plugins/CategoryAxisRenderer';
import { CanvasAxisLabelRenderer } from 'jqplot-exported/plugins/CanvasAxisLabelRenderer';
import { CanvasAxisTickRenderer } from 'jqplot-exported/plugins/CanvasAxisTickRenderer';
import { PieRenderer } from '../lib/PieRenderer';

export default function BuildingCtrl($scope, $routeParams, $location, $window, building, building_output, building_single_parameter_recommendations, building_multiple_parameter_recommendations, building_solar_generation, building_wind_generation, building_geothermal_generation, CityLookup) {
	'ngInject';

	$scope.building_conversion = {
	};

	$scope.m3_to_ft3_factor = 35.31466672148859;
	$scope.kwh_to_btu_factor = 3412.14163;
	$scope.m2_to_ft2_factor = 10.7639104;
	$scope.m_to_ft_factor = 3.28084;
	$scope.wm2_to_btuhft2_factor = 0.31699740062131493;
	$scope.w_m2K_to_btu_Fft2h_factor = 0.17610904672173008
	$scope.pa_to_psi_factor = 0.000145038;
	var l_to_gal_factor = 0.264172;
	var m3_to_kWh = 10.6278; // Convert natural gas consumption to kWh
	var cord_to_kWh = 5500; //Convert wood pellets to kWh
	var gj_per_m2_to_mmbtu_per_ft2 = 0.0879872;
	var mj_per_m2_to_mmbtu_per_ft2 = gj_per_m2_to_mmbtu_per_ft2 / 1000;
	var mj_per_m2_to_btu_per_ft2 = gj_per_m2_to_mmbtu_per_ft2 * 1000;

	// Heating energy use is just complicated.  Thus wrap it's own function
	(function (first_unit_decimal_rounding, second_unit_decimal_rounding) {
		second_unit_decimal_rounding = typeof second_unit_decimal_rounding !== 'undefined' ? second_unit_decimal_rounding : first_unit_decimal_rounding;
		var scope_change = false;
		var factor_to_secondary = 0;
		var factor_to_kWh = 0;

		$scope.$watch('[building.heating_energy_consumption, building.heating_energy_type]', function(newValue) {
			if(scope_change == true) {
				scope_change = false;
				console.log("FAIL FAST");
				return ;
			}
			scope_change = true;

			switch(newValue[1]) {
				case 'natural gas':
					factor_to_secondary = $scope.m3_to_ft3_factor;
					factor_to_kWh = 1/9.6e-2;
					break;
				case 'oil':
					factor_to_secondary = l_to_gal_factor;
					factor_to_kWh = 1/8.89e-2;
					break;
				case 'propane':
					factor_to_secondary = l_to_gal_factor;
					factor_to_kWh = 1/1.35e-1;
					break;
				case 'electricity':
					factor_to_secondary = $scope.kwh_to_btu_factor;
					factor_to_kWh = 1e0;
					break;
				case 'wood':
					factor_to_secondary = 1;
					factor_to_kWh = cord_to_kWh;
					break;
			}

			var oldConvertedValue = $scope.building_conversion.heating_energy_consumption;
			$scope.building_conversion.heating_energy_consumption = Number((newValue[0] * factor_to_secondary).toFixed(second_unit_decimal_rounding));
			if(oldConvertedValue === $scope.building_conversion.heating_energy_consumption) { //Since this might not change here, don't mark the scope as changing if it doesn't.
				scope_change = false;
			}
			$scope.converted_heating_energy_consumption = newValue[0] * factor_to_kWh;
		}, true);
		$scope.$watch('building_conversion.heating_energy_consumption', function(newValue) {
			if(scope_change == true) {
				scope_change = false;
				return ;
			}
			scope_change = true;
			$scope.building.heating_energy_consumption = Number((newValue / factor_to_secondary).toFixed(first_unit_decimal_rounding));;
		});
	})(0);

	$scope.outputs_types = ['lighting', 'equipment', 'dhw', 'fans', 'condenser_water_pumps', 'chilled_water_pumps', 'hot_water_pumps', 'chiller', 'boiler'];
	$scope.pretty_outputs_types = ['Lighting', 'Equipment', 'DHW', 'Fans', 'Condenser water pumps', 'Chilled water pumps', 'Hot water pumps', 'Chiller', 'Boiler'];

	function remake_chart(raw_data) {
		var chart_data = [];
		if($scope.requested_chart == "stacked") {
			$.each($scope.outputs_types, function() {
				var new_data = [];
				var area = raw_data[this];
				$.each($scope.months, function(index, value) {
					new_data.push([index+1, area[value.toLowerCase()]]);
				});
				chart_data.push(new_data);
			});
			$scope.chart_options = {
				title: "Consumption (kWh)",
				stackSeries: true,
				seriesDefaults:{
					renderer:BarRenderer
				},
				axes: {
					xaxis: {
						renderer: CategoryAxisRenderer,
						label: 'Month',
						labelRenderer: CanvasAxisLabelRenderer,
						tickRenderer: CanvasAxisTickRenderer,
						tickOptions: {
							angle: -30
						},
						ticks: $scope.months
					},
					yaxis: {
						// Don't pad out the bottom of the data range.  By default,
						// axes scaled as if data extended 10% above and below the
						// actual range to prevent data points right on grid boundaries.
						// Don't want to do that here.
						padMin: 0
					}
				},
				highlighter: {
					show:true,
					formatString:'%s, %P',
					tooltipContentEditor: function(str, seriesIndex, pointIndex, plot) {
						return $scope.pretty_outputs_types[seriesIndex];
					}
				},
				data: chart_data
			};
		} else if ($scope.requested_chart == "pie") {
			$.each($scope.outputs_types, function(index, value) {
				if (value == 'boiler') {
					return ; //Boiler not included, we are electricity only.
				}
				var sum = 0;
				var area = raw_data[this];
				$.each($scope.months, function(index, value) {
					sum += area[value.toLowerCase()];
				});
				chart_data.push([$scope.pretty_outputs_types[index], sum]);
			});
			$scope.chart_options = {
				data: [chart_data],
				seriesDefaults: {
					// Make this a pie chart.
					renderer: PieRenderer,
					rendererOptions: {
						fill: false,
						lineWidth: 5,
						sliceMargin: 0,
						// Put data labels on the pie slices.
						// By default, labels show the percentage of the slice.
						showDataLabels: true
					}
				},
				legend: { show:true, location: 'e' }
			};
		}
	}

	$scope.change_chart_type = function() {
		remake_chart($scope.building_output);
	}
	$scope.requested_chart = "stacked";

	function deal_with_new_output_data(data) {
		var sum_output_data = function(data) {
			var totalSum = 0;

			$.each($scope.outputs_types, function() {
				var sum = 0;
				var area = data[this];
				$.each($scope.months, function() {
					area[this.toLowerCase()] = area[this.toLowerCase()] || 0;
					sum += area[this.toLowerCase()];
				});
				area.sum = sum;
				totalSum += sum;
			});
			data.gasConsumption = data.boiler.sum;
			data.electricityConsumption = totalSum - data.gasConsumption;
			data.totalConsumption = (totalSum - data.gasConsumption) + data.gasConsumption;
		}
		$scope.benchmark_consumption = data.benchmark_calculations;
		sum_output_data($scope.benchmark_consumption);

		data = data.building_calculations;
		$scope.building_output = data;
		remake_chart(data);

		sum_output_data(data);
	}

	if('buildingId' in $routeParams){
		$scope.building = building.get({'buildingId': $routeParams.buildingId}, function(data) {
			$scope.master_building = angular.copy(data)
		});
		building_output.get({'buildingId': $routeParams.buildingId}, deal_with_new_output_data);
	} else {
		$scope.building = {
			'type': 'office',
			'has_consumption_data': true,
		};
	}

	$scope.isUnchanged = function() {
		return angular.equals($scope.building, $scope.master_building);
	}

	$scope.getNumber = function(num) {
		return new Array(num);
	}

	$scope.updateProp = function(prop, value) {
		$scope.building[prop] = value
	}

	var handle_solar = function(data) {
		$scope.renewables_generation.solar = data;
		var sum = 0;
		$.each(data.solar_energy, function(index, value) {
			sum += value;
		});
		data.solar_energy.sum = sum;
	}

	var handle_wind = function(data) {
		$scope.renewables_generation.wind = data;
		var sum = 0;
		$.each(data.wind_energy, function(index, value) {
			sum += value;
		});
		data.wind_energy.sum = sum;
	}

	var handle_geothermal = function(data) {
		$scope.renewables_generation.geothermal = data;
		var sum = 0;
		$.each(data.heating_energy, function(index, value) {
			sum += value;
		});
		data.heating_energy.sum = sum;
		var sum = 0;
		$.each(data.cooling_energy, function(index, value) {
			sum += value;
		});
		data.cooling_energy.sum = sum;
	}

	var process_recommendations_savings = function(data) {
		if (data.recommendations == undefined) {
			return ; // Don't go if recommendations are not yet fetched.
		}
		$.each(data.recommendations, function(index, value) {
			var electrical_savings = data.electricityConsumption - value.electrical_consumption;
			var heating_energy_savings = data.gasConsumption - value.natural_gas_consumption;
			value.savings_after_payback = (100 * data.building.electricity_annual_cost * electrical_savings / (data.building.electricity_growth_adjusted_interest_rate - data.building.electricity_annual_growth_rate) ) *
				(1 - Math.pow((1 + data.building.electricity_annual_growth_rate/100) / (1 + data.building.electricity_growth_adjusted_interest_rate/100), data.payback_period)) +
				(100 * data.building.heating_energy_annual_cost * heating_energy_savings / (data.building.heating_energy_growth_adjusted_interest_rate - data.building.heating_energy_annual_growth_rate) ) *
				(1 - Math.pow((1 + data.building.heating_energy_annual_growth_rate / 100) / (1 + data.building.heating_energy_growth_adjusted_interest_rate / 100), data.payback_period));
		});
	}
	$scope.$watch('{' +
		'recommendations:        single_parameter_recommendations.recommendations,'+
		'payback_period:         building.payback_period_for_single,'+
		'electricityConsumption: building_output.electricityConsumption,' +
		'gasConsumption:         building_output.gasConsumption,' +
		'building:               building' +
	'}', process_recommendations_savings, true);
	$scope.$watch('{' +
		'recommendations:        multiple_parameter_recommendations.recommendations,'+
		'payback_period:         building.payback_period_for_multiple,'+
		'electricityConsumption: building_output.electricityConsumption,' +
		'gasConsumption:         building_output.gasConsumption,' +
		'building:               building' +
	'}', process_recommendations_savings, true);

	$scope.renewables_generation = {};
	$scope.$watch('building', function(newValue, oldValue) {
		if (newValue === oldValue) { return; } // First run, ignore.
		building_output.fetch_for({}, $scope.building, deal_with_new_output_data);
		$scope.single_parameter_recommendations = building_single_parameter_recommendations.fetch_for({}, $scope.building);
		$scope.multiple_parameter_recommendations = building_multiple_parameter_recommendations.fetch_for({}, $scope.building);
		building_solar_generation.fetch_for({}, $scope.building, handle_solar);
		building_wind_generation.fetch_for({}, $scope.building, handle_wind);
		building_geothermal_generation.fetch_for({}, $scope.building, handle_geothermal);
	}, true);

	$scope.$watch('building.address1', function(title) {
		$scope.$emit('ChangePageTitle', title);
	})

	var percent_difference = function(values) {
		return Math.round((values[0] - values[1]) / values[0] * 100);
	}
	$scope.$watch('[converted_heating_energy_consumption, building_output.gasConsumption]', function(values) {
		$scope.heatingEnergyCalibration = percent_difference(values);
	}, true);
	$scope.$watch('[building.electricity_consumption, building_output.electricityConsumption]', function(values) {
		$scope.electricityCalibration = percent_difference(values);
	}, true);

	var calculate_energy_use_intensity = function(building, consumption) {
		// The extra unary + operations are to ensure that the inputs are treated as numbers, not strings.  Don't remove.
		return Math.round((consumption * 3.6) / ((+building.num_storeys + +building.number_of_basements) * +building.floor_area));
	}

	$scope.$watch('{num_storeys: building.num_storeys, number_of_basements: building.number_of_basements, floor_area: building.floor_area, consumption: building_output.totalConsumption, benchmark_consumption: benchmark_consumption}', function(val) {
		$scope.energy_use_intensity = calculate_energy_use_intensity(val, val.consumption);
		$scope.energy_use_intensity_imperial = Math.round($scope.energy_use_intensity * mj_per_m2_to_btu_per_ft2);
		if (val.benchmark_consumption !== undefined) { // If there are no results, don't bother.
			$scope.$emit('ChangeMeterValue', $scope.energy_use_intensity, calculate_energy_use_intensity(val, val.benchmark_consumption.totalConsumption));
		}
	}, true);

	$scope.$watch('{electricity_consumption: building_output.electricityConsumption, heating_energy_consumption: building_output.gasConsumption}', function(val) {
		$scope.ghg_emissions = Math.round(6.91e-4 * val.electricity_consumption + 1.706e-4 * val.heating_energy_consumption);
	}, true);

	$scope.$watch('[building.country, building.postal_code]', (val) => {
		let weather = {}
		$scope.weather = weather
		CityLookup.fetchCity(val[0], val[1]).then((data) => weather.city = data.city)
	}, true)

	$scope.gridOptions = {
		data : 'buildings',
		multiSelect: false
	};

	$scope.outputGridOptions = {
		data : 'outputs_types_grid',
		multiSelect: false
	};

	$scope.outputs_types_grid = [];
	$.each($scope.outputs_types, function() {
		$scope.outputs_types_grid.push({'Type': this});
	});

	$scope.submit_form = function() {
		building.save({}, $scope.building, function(ret) {
			if('new_id' in ret) {
				$location.path('/building/'+ret.new_id);
				$scope.$emit('RefetchBuildingList');
			} else {
				$scope.master_building = angular.copy($scope.building);
			}
		});
	}

	$scope.generate_report = function() {
		$window.location.assign('/api.php/building_report?buildingId='+$routeParams.buildingId);
	}

	$scope.chart_options = {data: [[0]]};

	$scope.menu_items = [
		{
			'name': 'general',
			'text': 'General',
			'items': [
				{
					'name': 'address',
					'text': 'Address',
					'output_page': 'building_map'
				},
				{
					'name': 'consumption',
					'text': 'Consumption'
				},
				{
					'name': 'built',
					'text': 'Built'
				},
				{
					'name': 'geometry',
					'text': 'Geometry'
				},
				{
					'name': 'occupancy',
					'text': 'Occupancy'
				}
			]
		},
		{
			'name': 'envelope',
			'text': 'Envelope',
			'items': [
				{
					'name': 'wall',
					'text': 'Wall'
				},
				{
					'name': 'window',
					'text': 'Windows'
				},
				{
					'name': 'roof',
					'text': 'Roof'
				}
			]
		},
		{
			'name': 'electrical',
			'text': 'Electrical',
			'items': [
				{
					'name': 'lighting',
					'text': 'Lighting'
				},
				{
					'name': 'plugload',
					'text': 'Plug'
				}
			]
		},
		{
			'name': 'hvac',
			'text': 'HVAC',
			'items': []
		},
		{
			'name': 'ecms',
			'text': 'Retrofit',
			'items': [
				{
					'name': 'single',
					'text': 'Single',
					'output_page': 'building_single_recommendations'
				},
				{
					'name': 'multiple',
					'text': 'Deep',
					'output_page': 'building_multiple_recommendations'
				},
				{
					'name': 'rates',
					'text': 'Rates'
				},
			]
		},
		{
			'name': 'renewables',
			'text': 'Renewable',
			'items': [
				{
					'name': 'general',
					'text': 'General',
					'output_page': 'building_renewables_generation'
				},
				{
					'name': 'solar',
					'text': 'Solar',
					'output_page': 'building_renewables_generation'
				},
				{
					'name': 'wind',
					'text': 'Wind',
					'output_page': 'building_renewables_generation'
				},
				{
					'name': 'geothermal',
					'text': 'Geothermal',
					'output_page': 'building_renewables_generation'
				},
			]
		},
		{
			'name': 'report',
			'text': 'Report',
			'items': []
		}
	];

    var random_page_value = "" + Math.random();
	$scope.change_input = function(new_page, item) {
		$scope.input_below_page = 'partials/inputs/' + new_page + '.html?r=' + random_page_value;
		$scope.input_help_below_page = 'partials/inputs_help/' + new_page + '.html?r=' + random_page_value;

		var new_page_split = new_page.split('_');
		$scope.input_section = new_page_split[0];
		$scope.input_page = new_page_split[1];

		if(item === undefined || item.output_page === undefined) {
			$scope.output_below_page = 'partials/outputs/building_outputs.html?r=' + random_page_value;
		} else {
			$scope.output_below_page = 'partials/outputs/' + item.output_page + '.html?r=' + random_page_value;
		}
	}
	$scope.change_input('general_address', $scope.menu_items[0].items[0]);

	$scope.heating_types = ['natural gas', 'oil', 'propane', 'electricity', 'wood'];
	$scope.consumption_options = [{key: true, value: 'Existing Building'}, {key:false, value: 'New Building'}]
}
