'use strict';

import angular from 'angular';

import country_option from './country-option';
import province_option from './province-option';

import sub_menu from './sub-menu.component'

export default angular.module('components', [country_option, province_option, sub_menu])
.name;
